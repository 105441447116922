import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  hidebreadcrum : boolean= true;
  constructor() { }

  currentDate: Date = new Date(); 
  
  ngOnInit(): void {
  }
  cslides = [
    {
      img: "assets/img/college/college-01.png",
      college: "Queens Location",
      course: "8 Courses" 
    },
    {
      img: "assets/img/college/college-02.png",
      college: "Harlem Location",
      course: "8 Courses" 
    },
    // {
    //   img: "assets/img/college/college-01.png",
    //   college: "London Business School",
    //   course: "40 Courses" 
    // },
    // {
    //   img: "assets/img/college/college-02.png",
    //   college: "Imperial College London",
    //   course: "65 Courses" 
    // },
  ];
  islides =[
    {
      img: "assets/img/instructors/mo.webp",
      name: "Mo Agbaje, CPP",
      subject: "PRESIDENT / CEO",
      university: "Queens Location",
      expertise: "Security Expert",
      years: "25 years"
    },
    {
      img: "assets/img/instructors/t0.webp",
      name: "Ray Agbaje",
      subject: "ASST. VICE PRESIDENT / OPERATIONS",
      university: "Harlem Location",
      expertise: "Supervisor",
      years: "5 years"
    },
    {
      img: "assets/img/instructors/mike.webp",
      name: "Mike Abi",
      subject: "ASST. VICE PRESIDENT / TECHNOLOGY",
      university: "Harlem Location",
      expertise: "Instructor",
      years: "5 years"
    },
    {
      img: "assets/img/instructors/kianna.webp",
      name: "Kianna Sanchez",
      subject: "ASSOCIATE / OPERATIONS",
      university: "Harlem Location",
      expertise: "Associate",
      years: "2 years"
    },
    {
      img: "assets/img/instructors/rebecca.webp",
      name: "Rebecca Idowu",
      subject: "VICE PRESIDENT",
      university: "Queens Location",
      expertise: "Instructor",
      years: "5 years"
    },
  ];
  rslides =[
    {
      img: "assets/img/review/review-01.jpg",
      name: "Magueda Saint-Jean",
      subject: "7 reviews",
      desc: "I just completed my 16 hour class with them today.. this is beautiful and wonderful school. Very informative and very professional.. the owner Mr. Moe of the school is absolutely lovely and sweet and the entire security school the staff the people are sweet and wonderful. I’m grateful I came here and a wonderful experience"
    },
    {
      img: "assets/img/review/review-02.jpg",
      name: "Khadijah Muhammad",
      subject: "9 reiews",
      desc: "Very professional, friendly staff, and Mr. Moe made the training fun!"
    },
    {
      img: "assets/img/review/review-03.jpg",
      name: "Hellen Gyamfi",
      subject: "1 review",
      desc: "Training was very informative. Smooth process, I highly recommend"
    },
  ];
  reviewSliderConfig = {
    infinite: true,
			slidesToShow: 2,
			speed: 500,
			autoplay:false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1
					}
				}
			]
  };
  instructorSliderConfig ={
    infinite: true,
		focusOnSelect: true,
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					centerMode: false
				}
			},
			{
				breakpoint: 670,
				settings: {
					slidesToShow: 1,
					centerMode: false,
				}
			}
			]
  };
  collegeSliderConfig = {
    infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3
				}
			},
			{
			  breakpoint: 800,
			  settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1
				}
			}		  
    ]
  };
}
