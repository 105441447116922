<!-- Start Navigation -->
<!-- Header -->
<!-- <header [ngClass]= "[activeRoute === '' ? 'header min-header' : 'header']"> -->
<header [ngClass]="[activeRoute === '' ? 'header' : 'header']">
    <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
            <a id="mobile_btn" href="javascript:void(0);">
                <span class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <a routerLink='/' class="navbar-brand logo">
                <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
            </a>
        </div>
        <div class="main-menu-wrapper">
            <div class="menu-header">
                <a routerLink='/' class="menu-logo">
                    <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
                </a>
                <a id="menu_close" class="menu-close" href="javascript:void(0);">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <!-- <ul class="main-nav">
                <li [ngClass]="[activeRoute === '' ? 'active' : '']"><a routerLink='/'>HOME</a></li>
                <li class="has-submenu" [ngClass]="[activeRoute === 'instructors' ? 'active' : '']">
                    <a href="">INSTRUCTORS <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li [ngClass]="[active2Route === 'instructor-dashboard' ? 'active' : '']"><a
                                routerLink='/instructors/instructor-dashboard'>Instructors Dashboard</a></li>
                        <li [ngClass]="[active2Route === 'courses' ? 'active' : '']"><a
                                routerLink='/instructors/courses'>Courses</a></li>
                        <li [ngClass]="[active2Route === 'appointments' ? 'active' : '']"><a
                                routerLink='/instructors/appointments'>Appointments</a></li>
                        <li [ngClass]="[active2Route === 'schedule-timings' ? 'active' : '']"><a
                                routerLink='/instructors/schedule-timings'>Schedule Timing</a></li>
                        <li [ngClass]="[active2Route === 'my-student' ? 'active' : '']"><a
                                routerLink='/instructors/my-student'>Students List</a></li>
                        <li [ngClass]="[active2Route === 'student-profile' ? 'active' : '']"><a
                                routerLink='/instructors/student-profile'>Students Profile</a></li>
                        <li [ngClass]="[active2Route === 'chat-instructor' ? 'active' : '']"><a
                                routerLink='/instructors/chat-instructor'>Chat</a></li>
                        <li [ngClass]="[active2Route === 'invoices' ? 'active' : '']"><a
                                routerLink='/pages/invoices'>Invoices</a></li>
                        <li [ngClass]="[active2Route === 'instructor-profile-settings' ? 'active' : '']"><a
                                routerLink='/instructors/instructor-profile-settings'>Profile Settings</a></li>
                        <li [ngClass]="[active2Route === 'reviews' ? 'active' : '']"><a
                                routerLink='/instructors/reviews'>Reviews</a></li>
                        <li [ngClass]="[active2Route === 'instructor-register' ? 'active' : '']"><a
                                routerLink='/instructors/instructor-register'>Instructor Register</a></li>
                    </ul>
                </li>
                <li class="has-submenu" [ngClass]="[activeRoute === 'students' ? 'active' : '']">
                    <a href="">STUDENTS <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li class="has-submenu">
                            <a href="#">Instructors</a>
                            <ul class="submenu">
                                <li [ngClass]="[active2Route === 'map-grid' ? 'active' : '']"><a
                                        routerLink='/students/map-grid'>Map Grid</a></li>
                                <li [ngClass]="[active2Route === 'map-list' ? 'active' : '']"><a
                                        routerLink='/students/map-list'>Map List</a></li>
                            </ul>
                        </li>
                        <li [ngClass]="[active2Route === 'search' ? 'active' : '']"><a
                                routerLink='/students/search'>Search Instructor</a></li>
                        <li [ngClass]="[active2Route === 'instructor-profile' ? 'active' : '']"><a
                                routerLink='/students/instructor-profile'>Instructor Profile</a></li>
                        <li [ngClass]="[active2Route === 'booking' ? 'active' : '']"><a
                                routerLink='/students/booking'>Booking</a></li>
                        <li [ngClass]="[active2Route === 'checkout' ? 'active' : '']"><a
                                routerLink='/students/checkout'>Checkout</a></li>
                        <li [ngClass]="[active2Route === 'booking-success' ? 'active' : '']"><a
                                routerLink='/students/booking-success'>Booking Success</a></li>
                        <li [ngClass]="[active2Route === 'student-dashboard' ? 'active' : '']"><a
                                routerLink='/students/student-dashboard'>Student Dashboard</a></li>
                        <li [ngClass]="[active2Route === 'favourites' ? 'active' : '']"><a
                                routerLink='/students/favourites'>Favourites</a></li>
                        <li [ngClass]="[active2Route === 'chat' ? 'active' : '']"><a
                                routerLink='/students/chat'>Chat</a></li>
                        <li [ngClass]="[active2Route === 'profile-settings' ? 'active' : '']"><a
                                routerLink='/students/profile-settings'>Profile Settings</a></li>
                        <li [ngClass]="[active2Route === 'change-password' ? 'active' : '']"><a
                                routerLink='/students/change-password'>Change Password</a></li>
                    </ul>
                </li>
                <li class="has-submenu" [ngClass]="[activeRoute === 'pages' ? 'active' : '']">
                    <a href="">PAGES <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li [ngClass]="[active2Route === 'voice-call' ? 'active' : '']"><a
                                routerLink='/pages/voice-call'>Voice Call</a></li>
                        <li [ngClass]="[active2Route === 'video-call' ? 'active' : '']"><a
                                routerLink='/pages/video-call'>Video Call</a></li>
                        <li [ngClass]="[active2Route === 'search' ? 'active' : '']"><a
                                routerLink='/students/search'>Search Instrctors</a></li>
                        <li [ngClass]="[active2Route === 'calendar' ? 'active' : '']"><a
                                routerLink='/pages/calendar'>Calendar</a></li>
                        <li [ngClass]="[active2Route === 'components' ? 'active' : '']"><a
                                routerLink='/pages/components'>Components</a></li>
                        <li class="has-submenu">
                            <a routerLink='/pages/invoices'>Invoices</a>
                            <ul class="submenu">
                                <li [ngClass]="[active2Route === 'invoices' ? 'active' : '']"><a
                                        routerLink='/pages/invoices'>Invoices</a></li>
                                <li [ngClass]="[active2Route === 'invoice-view' ? 'active' : '']"><a
                                        routerLink='/pages/invoice-view'>Invoice View</a></li>
                            </ul>
                        </li>
                        <li [ngClass]="[active2Route === 'blank-page' ? 'active' : '']"><a
                                routerLink='/pages/blank-page'>Starter Page</a></li>
                        <li [ngClass]="[active2Route === 'login' ? 'active' : '']"><a
                                routerLink='/pages/login'>Login</a></li>
                        <li [ngClass]="[active2Route === 'register' ? 'active' : '']"><a
                                routerLink='/pages/register'>Register</a></li>
                        <li [ngClass]="[active2Route === 'forgot-password' ? 'active' : '']"><a
                                routerLink='/pages/forgot-password'>Forgot Password</a></li>
                    </ul>
                </li>
                <li class="has-submenu" [ngClass]="[activeRoute === 'blog' ? 'active' : '']">
                    <a href="">BLOG <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li [ngClass]="[active2Route === 'blog-list' ? 'active' : '']"><a
                                routerLink='/blog/blog-list'>Blog List</a></li>
                        <li [ngClass]="[active2Route === 'blog-grid' ? 'active' : '']"><a
                                routerLink='/blog/blog-grid'>Blog Grid</a></li>
                        <li [ngClass]="[active2Route === 'blog-details' ? 'active' : '']"><a
                                routerLink='/blog/blog-details'>Blog Details</a></li>
                    </ul>
                </li>
                <li class="searchbar">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <div class="togglesearch" style="display: none;">
                        <form>
                            <div class="input-group">
                                <input type="text" class="form-control">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-primary">search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
            </ul> -->
        </div>
        <ul class="main-nav" *ngIf="isTime">
                <li><a
                        routerLink='/pages/videos'>Media</a>
                </li>
        </ul>
        <ul class="nav header-navbar-rht">
                
                <li class="course-amt">
                        <a routerLink='#' class="user-circle"><img src="assets/img/phone.png" width="22" alt=""></a> 
                        <a routerLink='#' class="course">
                            <span>+1(718)977-0080 |</span>
                            <p>+1(212) 901-3248 |</p>
                        </a>									
                    </li>
            <li><a routerLink='/pages/login'>Log in</a></li>
            <li><a routerLink='/pages/register' class="login-btn">Signup </a></li>
            <li class="lang-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"><img src="assets/img/flags/en.png"
                        alt="Lang"> EN</a>
                <div class="dropdown-menu">
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="assets/img/flags/us.png" alt="" height="16"> ENG
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="assets/img/flags/fr.png" alt="" height="16"> FRA
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="assets/img/flags/es.png" alt="" height="16"> SPN
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="assets/img/flags/de.png" alt="" height="16"> GER
                    </a>
                </div>
            </li>
            <!-- <li class="course-amt">
                <a routerLink='/instructors/courses' class="user-circle"><img src="assets/img/course.png" width="22" alt=""></a> 
                <a routerLink='/instructors/courses' class="course">
                    <span>Courses</span>
                    <p>$200</p>
                </a>									
            </li> -->
        </ul>
    </nav>
</header>
<!-- /Header -->