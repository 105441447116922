<!-- Footer -->	
<footer class="footer">
				
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-about">
                        <h2 class="footer-title">ABOUT Continuum Security</h2>
                        <div class="footer-about-content">
                            <p>We are dedicated to providing top-tier security guard training to equip individuals with the skills and knowledge necessary for a successful career in security. Our expert instructors bring years of industry experience, ensuring you receive practical, hands-on training that meets the highest standards. Join us today and take the first step toward a rewarding career in security. </p>
                            
                        </div>
                    </div>
                    <!-- /Footer Widget -->
                    
                </div>
                
                <div class="col-lg-3 col-md-6">
                
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">POPULAR COURSES</h2>
                        <ul>
                            <!-- <li><a routerLink='/instructors/courses'>Development</a></li>
                            <li><a routerLink='/instructors/courses'>Business</a></li>
                            <li><a routerLink='/instructors/courses'>IT & Software</a></li>
                            <li><a routerLink='/instructors/courses'>Office Productivity</a></li>
                            <li><a routerLink='/instructors/courses'>Personal Development</a></li> -->
                            <li><a routerLink='#'>Development</a></li>
                            <li><a routerLink='#'>Business</a></li>
                            <li><a routerLink='#'>IT & Software</a></li>
                            <li><a routerLink='#'>Office Productivity</a></li>
                            <li><a routerLink='#'>Personal Development</a></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->
                    
                </div>
                
                <div class="col-lg-3 col-md-6">
                
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">SUPPORT</h2>
                        <ul>
                            <!-- <li><a routerLink='/students/booking'>Booking</a></li>
                            <li><a routerLink='/students/chat'>Chat</a></li>
                            <li><a routerLink='/instructors/reviews'>Reviews</a></li>
                            <li><a routerLink='/students/favourites'>Favourites </a></li>
                            <li><a routerLink='/instructors/college'>College</a></li> -->
                            <li><a routerLink='#'>Booking</a></li>
                            <li><a routerLink='#'>Chat</a></li>
                            <li><a routerLink='#'>Reviews</a></li>
                            <li><a routerLink='#'>Favourites </a></li>
                            <li><a routerLink='#'>College</a></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->
                    
                </div>
                
                <div class="col-lg-3 col-md-6">
                
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-contact">
                        <h2 class="footer-title">NEWSLETTER</h2>
                        <p> Sign Up to Our Newsletter to Get Latest Updates & Services</p>
                        <div class="my-3 footer-newsletter">
                            <form class="input-group">
                            <input type="email" placeholder="Enter Your Email">
                            <div>
                                <button class="btn btn-newsletter" type="submit"><i class="fa fa-paper-plane text-white" aria-hidden="true"></i></button>
                            </div>
                            </form>
                        </div>	
                    </div>
                    <!-- /Footer Widget -->
                    
                </div>
                
            </div>
        </div>
    </div>
    <!-- /Footer Top -->
        
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
        
            <!-- Copyright -->
            <div class="copyright">
                <div class="row">
                    <div class="col-md-4 col-lg-4">
                        <div class="copyright-text">
                            <p class="mb-0">© 2021 All Rights Reserved</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="copyright-text center-text">
                            <p class="mb-0"><a routerLink='#'>Terms and Conditions</a> - <a routerLink='#'>Privacy Policy</a></p>
                            <!-- <p class="mb-0"><a routerLink='/pages/term-condition'>Terms and Conditions</a> - <a routerLink='/pages/privacy-policy'>Privacy Policy</a></p> -->
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-4 right-text">
                        <div class="social-icon">
                                <ul>
                                    <li><a href="#" class="icon" target="_blank"><i class="fab fa-facebook-f"></i> </a></li>
                                    <li><a href="#" class="icon" target="_blank"><i class="fab fa-twitter"></i> </a></li>
                                    <li><a href="#" class="icon" target="_blank"><i class="fab fa-instagram"></i> </a></li>
                                    <li><a href="#" class="icon" target="_blank"><i class="fab fa-linkedin-in"></i> </a></li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
            <!-- /Copyright -->
            
        </div>
    </div>
    <!-- /Footer Bottom -->
    
</footer>
<!-- /Footer -->