<!-- Home Banner -->
<section class="section home-banner row-middle">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-7">
                <div class="banner-content">
                    <div class="inner-banner">
                        <p>CONTINUUM SECURITY CONSULTANTS INC.</p> 
                        <h1>Achieve Your Dreams.</h1>
                        <h1>Book your Course.</h1>
                        <div class="btn-item">
                        <a class="btn get-btn" routerLink='/instructors/college'>Get Started</a>
                        <a class="btn courses-btn" routerLink='/instructors/college'>All Courses</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Home Banner -->

<!-- Our Colleges -->
<section class="section college">
<div class="container">
    <div class="row">
        <div class="col-12 col-md-7 mx-auto">
            <div class="section-header text-center">
                <h5>Top Locations</h5>
                <h2 class="header-title">DISCOVER OUR LOCATIONS</h2>
            </div>
        </div>
        
        <div class="col-md-12">
            <!-- Slider -->
            <ngx-slick-carousel class="carousel college-slider" #slickModal="slick-carousel" [config]="collegeSliderConfig">
                <div ngxSlickItem *ngFor="let slide of cslides">
                    <!-- Slider Item -->
                    <div class="services-item">
                        <div class="img-part">
                            <img src="{{slide.img}}" class="img-fluid" alt="">
                        </div>
                        <div class="content-part">
                        <div class="content-text">
                            <h3>{{slide.college}}</h3>
                            <h4 class="title">{{slide.course}}</h4>
                        </div>
                        </div>
                    </div>
                    <!-- /Slider Item -->
                </div>
            </ngx-slick-carousel>
            <!-- /Slider -->
        </div>
    </div>
            
    <div class="row">
        <div class="col-12">
            <div class="clg see-all text-center"> 
                <a routerLink='/instructors/college' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
            </div>
        </div>
    </div>
    <!--- End Colleges  -->
    
    <!--- Latest Courses  -->
    <div class="clg-course">
    <div class="row">
        <div class="col-12 col-md-7 mx-auto">
            <div class="section-header text-center">
                <h5>Latest Course</h5>
                <h2 class="header-title">WE PROVIDE BEST COURSES</h2>
            </div>
        </div>
    </div>
        
    <div class="row">
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-01.jpg" alt="" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$80.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens / Harlem - Locations</h5>
                    <h2><a routerLink='/students/booking'>8Hr Pre-Assignment Training</a></h2>
                    <p>Security / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>8 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->				
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-02.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$80.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens / Harlem - Locations</h5>
                    <h2><a routerLink='/students/booking'>8Hr Annual Renewal Training</a></h2>
                    <p>Security / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>8 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-03.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$150.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens / Harlem - Locations</h5>
                    <h2><a routerLink='/students/booking'>16Hr On The Job Training</a></h2>
                    <p>Security / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>2 Days</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-04.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$80.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-02.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens Location Only</h5>
                    <h2><a routerLink='/students/booking'>6 Hr Defensive Driving Class</a></h2>
                    <p>Driving / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-05.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-02.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens Location Only</h5>
                    <h2><a routerLink='/students/booking'>CPR Training Course</a></h2>
                    <p>Health / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-06.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-02.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens Location Only</h5>
                    <h2><a routerLink='/students/booking'>First Aid Training Course </a></h2>
                    <p>Health / Trainiing / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-07.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-02.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens Location Only</h5>
                    <h2><a routerLink='/students/booking'>AED Training Course</a></h2>
                    <p>Health / Training / Courses</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Hours</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/students/booking'><img src="assets/img/courses/course-08.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">5.0</div>
                            <div class="right">$400.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Queens / Harlem - Locations</h5>
                    <h2><a routerLink='/students/booking'>New License</a></h2>
                    <p>Technology / Space / Electrical</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>25</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>3 Days</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/students/booking' class="btn course-btn">Book Now</a>
                        <!-- <a routerLink='/students/booking' class="btn course-btn">Book Now</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
    </div>
    
    <div class="row">
        <div class="col-12">
            <div class="see-all  text-center"> 
                <a routerLink='#' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
                <!-- <a routerLink='/instructors/courses' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a> -->
            </div>
        </div>
    </div>
    
    </div>
</div>
</section>
<!-- END courses -->

<!-- Students Courses -->
<section class="section student">
<div class="container">
        <div class="row">
        <div class="col-md-10 mx-auto"> 
            <h5>We Are Best</h5>
            <h3>5500+ STUDENTS TRUSTED US</h3>
            <p>Join over 5000 students who have chosen us for their educational journey. With a proven 
                track record of excellence, personalized support, and innovative learning solutions, 
                we're dedicated to helping students achieve their goals. Discover why our trusted 
                programs and expert guidance make us the preferred choice for learners. Your success starts here!</p>
            <div class="button"><a routerLink='#' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a></div>
            <!-- <div class="button"><a routerLink='/instructors/courses' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a></div> -->
        </div>
        </div>
    </div>
</section>
<!-- End Students Courses -->	

<!-- Top Instructor -->
<section class="section instructor">
<div class="container">
        <div class="row">
            <div class="col-md-7 col-sm-12 col-12 mx-auto">
                    <div class="section-header text-center">
                        <h5>OUR INSTRUCTORS</h5>
                        <h2 class="header-title">MEET OUR EXPERT INSTUCTORS</h2>
                    </div>
            </div>
        </div>
            
        <div class="row">
            <div class="col-md-12">

                <!-- Slider -->
                <ngx-slick-carousel class="carousel instructor-slider" #slickModal="slick-carousel" [config]="instructorSliderConfig">
                    <div ngxSlickItem *ngFor="let slide of islides">
                        <!-- Instructor Item -->
                        <div class="instructor-item">
                            <div class="profile-box">
                                <div class="img-part">
                                    <img src="{{slide.img}}" class="img-fluid" alt="instructor">
                                </div>
                                <div class="profile-info">
                                    <div class="rating">
                                        <i class="fa fa-star checked"></i>
                                        <i class="fa fa-star checked"></i>
                                        <i class="fa fa-star checked"></i>
                                        <i class="fa fa-star checked"></i>
                                        <i class="fa fa-star checked"></i>
                                    </div>
                                    <h3><a routerLink='#'>{{slide.name}}</a></h3>
                                    <!-- <h3><a routerLink='/students/instructor-profile'>{{slide.name}}</a></h3> -->
                                    <h4>{{slide.subject}}</h4>		
                                </div>
                                <div class="instructor-line"></div>	
                            </div>
                            <div class="profile-detail">
                                <div class="row">
                                    <div class="col-6 d-flex align-items-center clg">
                                        <div class="icon"><img src="assets/img/icon/icon-01.png" class="img-fluid" alt="college"></div>
                                        <div><p>{{slide.university}}</p></div>
                                    </div>
                                    <div class="col-6 d-flex align-items-center clg">
                                        <div class="icon"><img src="assets/img/icon/icon-02.png" class="img-fluid" alt="college"></div>
                                        <div><h5>Expertise</h5> <p class="exp">{{slide.expertise}}</p> </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center"> 
                                    <div class="experience">Experience : {{slide.years}}</div>
                                    <a routerLink='/students/booking' class="btn btn-read">BOOK NOW</a>
                                </div>	
                            </div>	
                        </div>
                        <!-- /Instructor Item -->
                    </div>
                </ngx-slick-carousel>
                <!-- /Slider -->
            </div>
        </div>
            
        <div class="row">
            <div class="col-12">
                <div class="see-all  text-center"> 
                    <a routerLink='#' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
                    <!-- <a routerLink='/students/map-list' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a> -->
                </div>
            </div>
        </div>
</div>
</section>
<!-- End Instructor -->

<!-- Register -->
<!-- <section class="section register">
<div class="container">
    <div class="row">
        <div class="col-12 col-lg-5 col-md-7 mx-auto text-center">
            <h5>Register Now</h5>
            <h2 class="header-title">Get 5+ Courses Free Access on Register</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod tempor incididunt ut laboreet dolore magna</p>
        </div>
        <div class="col-12 col-lg-12 mx-auto text-center">
                <div class="reg-count d-flex justify-content-center">
                    <div class="count">
                        <span>45</span>
                        <p>Days</p>
                    </div>
                    <div class="count">
                        <span>12</span>
                        <p>Hrs</p>
                    </div>
                    <div class="count">
                        <span>36</span>
                        <p>Min</p>
                    </div>
                    <div class="count">
                        <span>36</span>
                        <p>Min</p>
                    </div>
                </div>
                <a routerLink='/pages/register' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
        </div>
    </div>
</div>
</section> -->
<!-- End Register -->


<!-- Review -->
<section class="section review">
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="section-header text-center">
                <h5>OUR REVIEWS</h5>
                <h2 class="header-title">CHECK OUR STUDENTS REVIEWS</h2>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-md-12">
            <!-- Slider -->
            <ngx-slick-carousel class="carousel review-slider" #slickModal="slick-carousel" [config]="reviewSliderConfig">
                <div ngxSlickItem *ngFor="let slide of rslides">
                    <!-- Review Widget -->
                    <div class="review-blog">
                        <div class="review-top d-flex align-items-center">
                            <div class="review-img">
                                <a href="javascript:void(0);"><img class="img-fluid" src="{{slide.img}}" alt="Post Image"></a>
                            </div>
                            <div class="review-info">
                                <h3>{{slide.name}}</h3>
                                <h5>{{slide.subject}}</h5>								
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0</span>
                                </div>
                            </div>
                        </div>
                        <div class="review-content">
                            <p>{{slide.desc}}</p>
                        </div>
                    </div>
                    <!-- / Review Widget -->
                </div>
            </ngx-slick-carousel>
            <!-- /Slider -->
        </div>					
    </div>
</div>
</section>
<!-- / Review -->

<!-- News -->
<section class="section news">
<div class="container">

    <div class="row">
        <div class="col-12">
            <div class="section-header text-center">
                <h5>OUR NEWS</h5>
                <h2 class="header-title">OUR LATEST NEWS</h2>
            </div>
        </div>
    </div>
    
    <div class="row">
    
        <!-- News Widget -->
        <div class="col-md-12">
            <div class="news-blog d-flex">
                <div class="news-img">
                    <img src="assets/img/news/news-01.jpg" class="img-fluid" alt="">
                </div>
                <div class="d-flex align-items-center">
                    <div class="news-content">
                        <span>{{ currentDate | date }}</span>
                        <h2><a routerLink='/blog/blog-details'>While the lovely Continuum team work</a></h2>
                        <p>We're thrilled to announce the launch of new advanced security training programs for 2025… </p>
                    </div>
                    <div class=""><a routerLink='#' class="btn btn-read">READ MORE</a></div>
                    <!-- <div class=""><a routerLink='/blog/blog-details' class="btn btn-read">READ MORE</a></div> -->
                </div>
            </div>
        </div>
        <!-- /News Widget -->
        
        <!-- News Widget -->
        <div class="col-md-12">
            <div class="news-blog d-flex">
                <div class="news-img">
                    <img src="assets/img/news/news-02.jpg" class="img-fluid" alt="">
                </div>
                <div class="d-flex align-items-center">
                    <div class="news-content">
                        <span>June 21, 2021</span>
                        <h2><a routerLink='/blog/blog-details'>New Location Opened!</a></h2>
                        <p>We're expanding! Continuum Security Trainig School is proud to announce the opening of our… </p>
                    </div>
                    <div class=""><a routerLink='#' class="btn btn-read">READ MORE</a></div>
                    <!-- <div class=""><a routerLink='/blog/blog-details' class="btn btn-read">READ MORE</a></div> -->
                </div>
            </div>
        </div>
        <!-- /News Widget -->
        
        <div class="col-md-12">
            <div class="see-all"> 
                <a routerLink='#' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
                <!-- <a routerLink='/blog/blog-grid' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a> -->
            </div>
        </div>
        
    </div>			
</div>
</section>
<!-- / News -->	